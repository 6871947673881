import { useState } from 'react';
import {storageAvailable} from '@/utils/storageAvailable';

import styles from './style.module.css';

const NAME = 'cookie-banner';

export const CookieBanner = () => {
  const [status, setStatus] = useState(() => {
    if (typeof window !== 'undefined' && storageAvailable('localStorage')) {
      return localStorage.getItem(NAME);
    }

    return '';
  });

  if (!storageAvailable('localStorage')) {
    return null;
  }

  if (
    status &&
    ((status === 'nextTimeLocal' && localStorage?.getItem(NAME) === 'nextTime') ||
      status === 'decline' ||
      status === 'accept')
  ) {
    return null;
  }

  const handleDecline = () => {
    localStorage.setItem(NAME, 'decline');
    setStatus('decline');
  };

  const handleAccept = () => {
    localStorage.setItem(NAME, 'accept');
    setStatus('accept');
  };

  const handleClose = () => {
    localStorage.setItem(NAME, 'nextTime');
    setStatus('nextTimeLocal');
  };

  const handleOpenCookieModal = (event) => {
    event.preventDefault();

    document.getElementById('cookie-policy-btn-link').click();
  };

  return (
    <div id="cookie-banner" className={styles.banner}>
      <p>
        We use cookies to ensure that we give you the best experience on our site. By accepting
        and/or continuing to , you agree to our use of such cookies.{' '}
        <a href="#" onClick={handleOpenCookieModal}>
          Learn more
        </a>
      </p>

      <div className={styles.actions}>
        <button type="button" onClick={handleDecline} className={`button ${styles.decline}`}>
          DECLINE
        </button>

        <button type="button" onClick={handleAccept} className={`button ${styles.accept}`}>
          ACCEPT
        </button>

        <button type="button" onClick={handleClose} className={`button ${styles.close}`} />
      </div>
    </div>
  );
};
