import { useEffect, useRef, useState } from 'react';

import playIconUrl from '@/assets/images/play.svg';
import pauseIconUrl from '@/assets/images/pause.svg';
import engSong from '@/assets/audio/eng.mp3';
import krSong from '@/assets/audio/kr.mp3';

import styles from './styles.module.css';

const INITIAL_SONG = {
  eng: false,
  kr: false
};

export const PlayButtons = () => {
  const audioRef = useRef({
    isEnded: false
  });
  const [audio] = useState({
    kr: new Audio(krSong),
    eng: new Audio(engSong)
  });
  const [isPlaying, setIsPlaying] = useState(INITIAL_SONG);

  useEffect(() => {
    audio.kr.load();
    audio.eng.load();
  }, []);

  useEffect(() => {
    audio.kr.addEventListener('ended', () => {
      audioRef.current = {
        isEnded: true
      };
      setIsPlaying(INITIAL_SONG);
    });
    audio.eng.addEventListener('ended', () => {
      audioRef.current = {
        isEnded: true
      };
      setIsPlaying(INITIAL_SONG);
    });

    return () => {
      audio.removeEventListener('ended', () => setIsPlaying(INITIAL_SONG));
    };
  }, [audio]);

  const handlePlay = async (event) => {
    const songLang = event.currentTarget.getAttribute('data-lang');

    if (isPlaying[songLang]) {
      audio[songLang].pause();

      setIsPlaying((prevState) => ({
        ...prevState,
        [songLang]: false
      }));

      return;
    }

    const isEngSong = songLang === 'eng';
    const bSong = isEngSong ? 'kr' : 'eng';
    const currentSongTime = isEngSong ? audio.eng.currentTime : audio.kr.currentTime;
    const time = audioRef.current.isEnded ? 0 : currentSongTime;

    audio[bSong].pause();
    audio[songLang].currentTime = time;

    try {
      await audio[songLang].play();
      audioRef.current = {
        isEnded: false
      };

      setIsPlaying({
        [songLang]: true,
        [bSong]: false
      });
    } catch (err) {
      console.error("Video is not supported in your browser");
    }
  };

  return (
    <>
      <div className={styles.title}>Listen</div>

      <div className={styles.playBox}>
        <button
          onClick={handlePlay}
          data-lang="eng"
          data-song={engSong}
          type="button"
          className={`${styles.playA} ${isPlaying.kr ? styles.playingBg : ''}`}
        >
          {isPlaying.eng ? (
            <img src={pauseIconUrl.src} alt="pause" />
          ) : (
            <>
              <span>A</span>
              <img className={styles.playIcon} src={playIconUrl.src} alt="play" />
            </>
          )}
        </button>

        <button
          type="button"
          onClick={handlePlay}
          data-lang="kr"
          data-song={krSong}
          className={`${styles.playB} ${isPlaying.eng ? styles.playingBg : ''}`}
        >
          {isPlaying.kr ? (
            <img src={pauseIconUrl.src} alt="pause" />
          ) : (
            <>
              <span>B</span>
              <img className={styles.playIcon} src={playIconUrl.src} alt="play" />
            </>
          )}
        </button>

        <svg
          className={isPlaying.eng || isPlaying.kr ? styles.playingBg : ''}
          width="166"
          height="48"
          viewBox="0 0 166 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M37.5682 4.20188C55.9014 15.9913 86.2139 22.005 107.062 15.2115C110.371 14.1277 113.681 12.806 116.99 10.6253C135.217 -0.424014 152.081 24.9391 134.741 37.4158C129.194 41.0769 122.205 40.8522 116.99 37.4158C110.729 33.4243 104.137 31.64 97.1344 30.5695C83.6989 28.8513 70.4487 30.9131 57.4236 34.7856C50.4742 36.9796 43.8822 39.6627 37.5682 43.8524C26.608 51.2935 11.6768 48.4519 4.22438 37.4951C-10.3362 15.2908 15.6876 -10.2045 37.5682 4.20188Z" />
          <path d="M128.432 4.20188C110.099 15.9913 79.7861 22.005 58.9379 15.2115C55.6287 14.1277 52.3194 12.806 49.0102 10.6253C30.783 -0.424014 13.9191 24.9391 31.2595 37.4158C36.8058 41.0769 43.7949 40.8522 49.0102 37.4158C55.2713 33.4243 61.8633 31.64 68.8656 30.5695C82.3011 28.8513 95.5513 30.9131 108.576 34.7856C115.526 36.9796 122.118 39.6627 128.432 43.8524C139.392 51.2935 154.323 48.4519 161.776 37.4951C176.336 15.2908 150.312 -10.2045 128.432 4.20188Z" />
        </svg>
      </div>

      <div className={styles.playBoxLang}>
        <span>English</span>
        <span>Korean</span>
      </div>
    </>
  );
};
